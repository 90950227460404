import React, {useEffect} from 'react'
import { Link } from 'gatsby'
import submitStyles from '../components/submit.module.css'


const Submit = () => {

    useEffect(() => {
        const time = setTimeout(() => {
            window.location.assign('/')
        }, 3000)

        return () => clearInterval(time)
    }, [])

    return (
        <div className={submitStyles.main}>
            <div className={submitStyles.thanks}>
                <h4>Thank you for contacting us!</h4>
                <Link to="/" className={submitStyles.return}>Return back</Link>
            </div>
        </div>
    )
}

export default Submit